<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <v-alert type="warning" v-if="event.notPublished">{{$t('eventmanager.not-published-msg')}}</v-alert>
      <EventHeader :event="event"/>

      <v-card-title class="title">{{$t('events.welcome.getting-started')}}</v-card-title>
      <v-card-text>Please follow these instructions to get started and connect with {{event.name}}.</v-card-text>

      <v-alert v-if="isConnected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: event.name})}}</p>
        <p>
          <span v-if="status.verification_code">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
          <span v-if="status.races">{{$t('events.welcome.joined-races', {races: status.races.join(', ') })}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
        </p>
        <p>
          <v-btn x-large :to="{name: 'eventWelcome', params: {id: this.event.id}}" :exact="true">
            <v-icon class="mr-2">fa-info-circle</v-icon>
            {{ $t('events.welcome.manage-connection') }}
          </v-btn>
        </p>
      </v-alert>

      <v-card-title class="subheader">Step 1: <strong>Locate your invite email</strong></v-card-title>
      <v-card-text>
        <p>Look for an email with the subject "<strong>Join {{event.name}} on {{tenant.name}}</strong>" in your mailbox. Can't find it? Please make sure to look in your spam/junk folders as well.</p>
        <p><v-btn outlined color="primary" @click="recoverInviteLink">Get Invite Link</v-btn></p>

      </v-card-text>

      <v-card-title class="subheader">Step 2: <strong>Join with the app or on the website</strong></v-card-title>
      <v-card-text>
        <p>Depending on your preference you can either download our app or join on our website. Click the link from the email or scan the included QR code to get started.</p>

        <v-expansion-panels tile class="mt-4">
          <v-expansion-panel>
            <v-expansion-panel-header>Don't have invite link?</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Can't find your invite link? No worries, use this wizard to quickly get started!</p>
              <p><v-btn class="primary" @click="recoverInviteLink">Get Invite Link</v-btn></p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Verification Code Already Claimed message?</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>When you get an error that your code is already used you might have accidentally created a second account. Please log out first and log back in with your first account. You will have received a Join Confirmation email which listed all your account details.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-title class="subheader">Step 3: <strong>Login or Register</strong></v-card-title>
      <v-card-text>
        <p>Connect using your preferred method, or continue without an account. Your challenge and code are preselected so you don't need to enter those again.</p>

        <v-expansion-panels tile class="mt-4">
          <v-expansion-panel>
            <v-expansion-panel-header>Using both app and website?</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>When you use both the app and website, please make sure to log in with the same account on both devices. E.g. when you connect with Garmin, please make sure to do so on both devices.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-title class="subheader">Step 4: <strong>Connect your favorite fitness tracker (optional)</strong></v-card-title>
      <v-card-text>
        <p>One you're part of the challenge you can connect your favorite fitness tracker to automatically track your steps and activities. Alternatively, you can use the integrated activity or step tracker from our app or use manual entry.</p>
      </v-card-text>



      <v-card-title class="subheader">Tutorials</v-card-title>
      <v-card-text>These how-to guides might be helpful to get you started in no time!</v-card-text>
      <v-row class="mx-3"> 
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Join%20challenge%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Join a Challenge</h3>
            <p>How to join a (step) challenge using our app.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Garmin%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Garmin</h3>
            <p>How to connect with Garmin.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Strava%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Strava</h3>
            <p>How to connect with Strava.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Apple%20Watch%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Apple Watch</h3>
            <p>How to connect your Apple Watch.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Fitbit%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Fitbit</h3>
            <p>How to connect your Fitbit.</p>
          </v-card>
        </v-col>
        <!-- hide voor non-step events? -->
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Enable%20Step%20Tracking%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Enable Step Tracking</h3>
            <p>How to enable step tracking in the app.</p>
          </v-card>
        </v-col>
        <!-- hide voor non-manual entries? -->
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20QuickEntry%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Add a Manual Entry</h3>
            <p>How to add a manual entry.</p>
          </v-card>
        </v-col>
      </v-row>      
      <br/>
    </v-card>

    <v-dialog v-model="showRecoveryDialog" max-width="500">
      <v-card>
        <v-card-title>Retrieve your join link</v-card-title>
        <v-card-text v-if="showQr">
          <p>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</p>
          <p>This is your <strong>personal</strong> join link for code <strong>{{joinInfo.join_code}}</strong> and can only be used once.</p>
          <img :src="`https://chart.googleapis.com/chart?cht=qr&chs=350x350&chl=${encodeURIComponent(joinInfo.join_url)}`" width="350" height="350"/>
        </v-card-text>
        <v-card-text v-else-if="joinInfo && joinInfo.join_url">
          <v-alert type="success">All good, we found your information!</v-alert>
          <p>On which device would you like to join?</p>
          <v-card :href="joinInfo.join_url" target="_blank" class="pa-4 mt-4">
            <h3>On <u>this</u> device</h3>
            <p>Download the app or join online, depending on the type of your device.</p>
          </v-card>
          <v-card @click="showQr=true" class="pa-4 mt-4">
            <h3>On <u>another</u> device</h3>
            <p>Show a QR code which you can scan with your phone to download the app and get started.</p>
          </v-card>
        </v-card-text>
        <v-card-text v-else-if="recoveryType=='CODE'">
          <p>Please enter your personal verification code:</p>
          <v-text-field v-model="code" autofocus />
          <v-alert v-if="joinInfo" type="warning">Cannot find information for this code. Please ensure you entered it correctly or contact the event organizer <a v-if="joinInfo.support_email" :href="`mailto:${joinInfo.support_email}`">at {{joinInfo.support_email}}</a> to get your link.</v-alert>
          <v-btn color="primary" :disabled="!code" @click="loadJoinStatusByCode">Find</v-btn>
        </v-card-text>
        <v-card-text v-else-if="recoveryType=='EMAIL'">
          <p>Please enter the email address used by this event:</p>
          <v-text-field v-model="email" autofocus />
          <v-alert v-if="joinInfo" type="warning">Cannot find information for this email address. Please ensure you have no typos in your email or contact the event organizer <a v-if="joinInfo.support_email" :href="`mailto:${joinInfo.support_email}`">at {{joinInfo.support_email}}</a> to get your link.</v-alert>
          <v-btn color="primary" :disabled="!email" @click="loadJoinStatusByEmail">Find</v-btn>
        </v-card-text>
        <v-card-text v-else>
          <p>How do you want to retrieve your link??</p>
          <v-card @click="recoveryType='CODE'" class="pa-4 mt-4">
            <h3>By <u>verification code</u></h3>
            <p>When you know your personal verification code.</p>
          </v-card>
          <v-card @click="recoveryType='EMAIL'" target="_blank" class="pa-4 mt-4">
            <h3>By <u>email</u></h3>
            <p>Enter your email to retrieve your link.</p>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="showRecoveryDialog=false;">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import SodispRules from '@/components/SodispRules.vue';
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import { getAuth } from "firebase/auth";

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    SodispRules,
    EventHeader,
    EventJoinCard,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      status: null,
      joinInfo: null,
      code: null,
      email: null,
      showRecoveryDialog: false,
      showQr: false,
      recoveryType: null,
    };
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.getEventStatus();
    });


    //test:
    //this.recoverInviteLink();
  },

  methods: {
      
    async getEventStatus() {
      if (this.user) {
        var response = await profileService.getEventStatus(this.event.id);
        this.status = response.data;
      }
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      await this.getEventStatus();
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
    async recoverInviteLink() {
      this.email = this.code = this.showQr = this.joinInfo = this.recoveryType = null;
      this.showRecoveryDialog = true;
    },
    async loadJoinStatusByCode() {
      var response = await eventService.getJoinInfo(this.event.id, this.code);
      this.joinInfo = response.data;
    },
    async loadJoinStatusByEmail() {
      var response = await eventService.getJoinInfoByEmail(this.event.id, this.email); 
      this.joinInfo = response.data;
    },

  },
  computed: {
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.welcome.getting-started'), disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

